import React from "react";
import { WagmiConfig, createClient } from "wagmi";
import { mainnet, goerli } from "wagmi/chains";
import { ConnectKitProvider, getDefaultClient } from "connectkit";
import { Journey } from "./Journey";

const alchemyId = process.env.REACT_APP_ALCHEMY_ID;

const chains = [mainnet];

const client = createClient(
    getDefaultClient({
        appName: "The Secrets of Lux",
        alchemyId,
        chains,
    }),
);

export const App = () => {

    return (
        <WagmiConfig client={client}>
            <ConnectKitProvider
                theme="default"
                mode="dark"
                options={{
                    embedGoogleFonts: true,
                }}
                customTheme={{
                    "--ck-font-family": '"Chakra Petch", sans',
                    "--ck-connectbutton-border-radius": "5px",
                    "--ck-connectbutton-box-shadow": "0px 0px 15px 3px #B900FF",
                    "--ck-connectbutton-font-size": "16px",
                    "--ck-connectbutton-background": "#070707",
                }}
            >
                <Journey />
            </ConnectKitProvider>
        </WagmiConfig>
    );
};
